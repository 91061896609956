@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 300;
  src: local('Ubuntu Light'), local('Ubuntu-Light'), url(ubuntu-light.woff) format('woff');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  src: local('Ubuntu'), url(ubuntu.woff) format('woff');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  src: local('Ubuntu Medium'), local('Ubuntu-Medium'), url(ubuntu-medium.woff) format('woff');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  src: local('Ubuntu Bold'), local('Ubuntu-Bold'), url(ubuntu-bold.woff) format('woff');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 300;
  src: local('Ubuntu Light Italic'), local('Ubuntu-LightItalic'), url(ubuntu-lightitalic.woff) format('woff');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 400;
  src: local('Ubuntu Italic'), local('Ubuntu-Italic'), url(ubuntu-italic.woff) format('woff');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 500;
  src: local('Ubuntu Medium Italic'), local('Ubuntu-MediumItalic'), url(ubuntu-mediumitalic.woff) format('woff');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 700;
  src: local('Ubuntu Bold Italic'), local('Ubuntu-BoldItalic'), url(ubuntu-bolditalic.woff) format('woff');
}
